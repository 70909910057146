import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UpdateCoverPhoto from './UpdateCoverPhoto';
import artistPlaceholder from '../assets/images/avat1.png';
import fetchProfile from '../redux/actions/user/fetchProfile';
import Loader from './Loader';
import Error from './Error';

const CoverPhotoCard = ({ provider }) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [status, setStatus] = useState('success');
  const [profileData, setProfileData] = useState(provider);

  const refetch = () => {
    setStatus('fetching');
    const initiate = async () => {
      try {
        const profData = await fetchProfile();
        setProfileData(profData);
        setStatus('success');
      } catch (error) {
        setStatus('error');
      }
    };
    initiate();
  };

  const imgSrc = () => {
    if (imageLoading) {
      return artistPlaceholder;
    }
    if (profileData.coverPhoto) {
      return profileData.coverPhoto;
    }
    return artistPlaceholder;
  };

  const DisplayData = ({ children }) => {
    let data;
    switch (status) {
      case 'success':
        data = <>{children}</>;
        break;
      case 'fetching':
        data = <Loader />;
        break;
      case 'no_data':
        data = (
          <Error
            title="No data found!"
            description="The artist does not exist!"
          />
        );
        break;
      default:
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
    }
    return data;
  };

  return (
    <div className="col-6">
      <div className="icon-div text-white bg-secondary rounded mt-4 py-3">
        <div className="provider">
          <DisplayData>
            <div className="artist-cover-pic yellow mx-auto">
              <img
                className="img-fluid rounded"
                src={imgSrc()}
                alt="Initials Pict"
                onLoad={() => setImageLoading(false)}
              />
            </div>
            <div className="larger-text mt-1">Cover Photo</div>
            <div className="small-text yellow">Artist</div>
            <button
              type="button"
              className="btn btn-primary px-3 py-1 my-2"
              data-toggle="modal"
              data-target="#UpdateCoverPhotoModal"
            >
              Update Picture
            </button>
            <UpdateCoverPhoto
              refetch={refetch}
              artistData={profileData.coverPhoto}
            />
          </DisplayData>
        </div>
      </div>
    </div>
  );
};

CoverPhotoCard.propTypes = {
  provider: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CoverPhotoCard;
