/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import withBackground from '../withBackground';
import SignUpNav from '../components/forms/SignUpNav';
import PersonalInfo from '../components/forms/PersonalInfo';
import Identification from '../components/forms/Identification';
import BankInfo from '../components/forms/BankInfo';
import ArtistInfo from '../components/forms/ArtistInfo';
import signupAction from '../redux/actions/user/signup';
import fetchBanksAction from '../redux/actions/payments/fetchBanks';
import Loader from '../components/Loader';
import Error from '../components/Error';

const SignUp = ({
  signupAction: signAction,
  signup,
  fetchBanksAction: fetchBanks,
  getBanks,
}) => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData1, setFormData1] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phoneNumber: '',
    password: '',
  });
  const [formData2, setFormData2] = useState({
    img: null,
    nationalIdCard: '',
  });
  const [formData3, setFormData3] = useState({
    bankName: '',
    bankCode: 0,
    accountNumber: '',
  });
  const [formData4, setFormData4] = useState({
    img: null,
    file: null,
    name: '',
    profilePhotoLink: '',
    checked: false,
  });
  const [banksData, setBanksData] = useState([]);
  const [status, setStatus] = useState('initial');
  const history = useHistory();

  useEffect(() => {
    if (signup.status === 'signup_success') {
      setLoading(false);
      return history.push('/signup/success');
    }
    if (signup.status === 'signup_error') {
      setLoading(false);
      return setError(`Error: ${signup.error.message}`);
    }

    if (status === 'initial') {
      fetchBanks();
      setStatus('fetching');
    }

    if (getBanks.status === 'error') {
      return setStatus('unknown_error');
    }

    if (getBanks.status === 'success') {
      setBanksData(getBanks.results);
      return setStatus('success');
    }

    return undefined;
  }, [signup, getBanks]);

  const handleNext = (data) => {
    if (step === 1) {
      setFormData1(data);
      setStep(step + 1);
    }
    if (step === 2) {
      setFormData2(data);
      setStep(step + 1);
    }
    if (step === 3) {
      setFormData3(data);
      setStep(step + 1);
    }
    if (step === 4) {
      setFormData4(data);
      const payload = new FormData();
      // const bankObj = {
      //   bankName: formData3.bankName,
      //   bankCode: formData3.bankCode,
      //   accountNumber: formData3.bankCode === 'MPS' ? `250${formData3.accountNumber}` : formData3.accountNumber,
      // };

      const bankObj = {
        bankName: 'Mobile Money',
        bankCode: 'MPS',
        accountNumber: `250${formData3.accountNumber}`,
      };

      payload.append('firstname', formData1.firstname);
      payload.append('lastname', formData1.lastname);
      payload.append('email', formData1.email);
      payload.append('phoneNumber', `+250${formData1.phoneNumber}`);
      payload.append('password', formData1.password);
      payload.append('nationalIdCard', formData2.nationalIdCard);
      payload.append('bank', JSON.stringify(bankObj));
      payload.append('name', data.name);
      payload.append('profilePhotoLink', data.profilePhotoLink);

      if (data.file) {
        payload.append('contract', data.file);
      }
      setLoading(true);
      setError('');
      signAction(payload);
    }
  };

  const handlePrev = (data) => {
    if (step === 2 && data !== null) {
      setFormData2(data);
      setStep(step - 1);
    }
    if (step === 3 && data !== null) {
      setFormData3(data);
      setStep(step - 1);
    }
    if (step === 4 && data !== null) {
      setFormData4(data);
      setStep(step - 1);
    } else {
      setStep(step - 1);
    }
  };

  const currentComponent = (val) => {
    switch (val) {
      case 1:
        return (
          <PersonalInfo
            onNext={handleNext}
            onPrev={handlePrev}
            step={step}
            formData={formData1}
          />
        );
      case 2:
        return (
          <Identification
            onNext={handleNext}
            onPrev={handlePrev}
            step={step}
            formData={formData2}
          />
        );
      case 3:
        return (
          <BankInfo
            onNext={handleNext}
            onPrev={handlePrev}
            step={step}
            formData={formData3}
            banksData={banksData}
          />
        );
      case 4:
        return (
          <ArtistInfo
            onNext={handleNext}
            onPrev={handlePrev}
            step={step}
            formData={formData4}
            loading={loading}
          />
        );
      default:
        return <></>;
    }
  };

  const DisplayData = ({ children }) => {
    let data;
    switch (status) {
      case 'success':
        data = <>{children}</>;
        break;
      case 'fetching':
        data = <Loader />;
        break;
      default:
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
    }
    return data;
  };

  return (
    <>
      <h5 className="text-center text-white">Sign Up</h5>
      <SignUpNav step={step} status="in-progress" />
      <DisplayData>{currentComponent(step)}</DisplayData>
      <p className="text-center text-danger" style={{ fontSize: 15 }}>
        {error}
      </p>
    </>
  );
};

SignUp.propTypes = {
  signup: PropTypes.objectOf(PropTypes.any).isRequired,
  getBanks: PropTypes.objectOf(PropTypes.any).isRequired,
  signupAction: PropTypes.func.isRequired,
  fetchBanksAction: PropTypes.func.isRequired,
};

const mapStateToProps = ({ signup, getBanks }) => ({
  signup,
  getBanks,
});

export default connect(mapStateToProps, {
  signupAction,
  fetchBanksAction,
})(withBackground(SignUp, 'signup'));
