import {
  UPDATE_ARTIST_SUCCESS,
  UPDATE_ARTIST_FAILED,
  CLEAR_UPDATE_ARTIST,
} from '../../actionTypes';

const initialState = {
  status: '',
  message: '',
  results: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ARTIST_SUCCESS:
      return {
        ...state,
        status: 'success',
        message: action.message,
        results: action.results,
      };
    case UPDATE_ARTIST_FAILED:
      return { ...state, status: 'error', error: action.error };

    case CLEAR_UPDATE_ARTIST:
      return { ...initialState, status: 'clearEdit_artist' };
    default:
      return state;
  }
};
