/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import withBackground from '../withBackground';
import SignUpNav from '../components/forms/SignUpNav';
import PersonalInfo from '../components/forms/PersonalInfo';
import Identification from '../components/forms/Identification';
import BankInfo from '../components/forms/BankInfo';
import ArtistInfo from '../components/forms/ArtistInfo';
import updateArtistAction from '../redux/actions/artists/updateArtist';
import fetchBanksAction from '../redux/actions/payments/fetchBanks';
import Loader from '../components/Loader';
import Error from '../components/Error';
import fetchProfile from '../redux/actions/user/fetchProfile';

const SignUp = ({
  updateArtistAction: updateAction,
  updateArtist,
  fetchBanksAction: fetchBanks,
  getBanks,
}) => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [profileData, setProfileData] = useState({});
  const [formData1, setFormData1] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phoneNumber: '',
    password: '',
  });
  const [formData2, setFormData2] = useState({
    img: null,
    nationalIdCard: '',
  });
  const [formData3, setFormData3] = useState({
    bankName: '',
    bankCode: 0,
    accountNumber: '',
  });
  const [formData4, setFormData4] = useState({
    file: null,
    img: null,
    name: '',
    profilePhotoLink: '',
  });
  const [banksData, setBanksData] = useState([]);
  const [status, setStatus] = useState('fetching');
  const [bankStatus, setBankStatus] = useState('initial');
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem('INGOMA_TEMP_ARTIST_TOKEN')) {
      return history.push('/login');
    }
    setError('');
    setLoading(false);
    const initiate = async () => {
      try {
        if (localStorage.getItem('INGOMA_TEMP_ARTIST_TOKEN')) {
          const profData = await fetchProfile('temp');
          setProfileData(profData);
          setStatus('success');
        }
      } catch (err) {
        setStatus('error');
      }
    };

    initiate();

    if (updateArtist.status === 'success') {
      setLoading(false);
      setError('');
      localStorage.removeItem('INGOMA_TEMP_ARTIST_TOKEN');
      return history.push('/application/update/success');
    }
    if (updateArtist.status === 'error') {
      setLoading(false);
      return setError(`Error: ${updateArtist.error.message}`);
    }

    if (bankStatus === 'initial') {
      fetchBanks();
      setBankStatus('fetching');
    }

    if (getBanks.status === 'error') {
      return setBankStatus('unknown_error');
    }

    if (getBanks.status === 'success') {
      setBanksData(getBanks.results);
      return setBankStatus('success');
    }

    return undefined;
  }, [updateArtist, getBanks]);

  const handleNext = (data) => {
    if (step === 1) {
      setFormData1(data);
      setStep(step + 1);
    }
    if (step === 2) {
      setFormData2(data);
      setStep(step + 1);
    }
    if (step === 3) {
      setFormData3(data);
      setStep(step + 1);
    }
    if (step === 4) {
      setFormData4(data);
      let payload;
      const bankObj = {
        bankName: formData3.bankName,
        bankCode: formData3.bankCode,
        accountNumber:
          formData3.bankCode === 'MPS'
            ? `250${formData3.accountNumber}`
            : formData3.accountNumber,
      };
      const check =
        formData1.firstname !== profileData.firstname ||
        formData1.lastname !== profileData.lastname ||
        formData1.phoneNumber !==
          profileData.phoneNumber.slice(4, profileData.phoneNumber.length) ||
        formData2.nationalIdCard !== profileData.nationalIdCard ||
        formData3.bankName !== profileData.bank.bankName ||
        formData3.bankCode !== profileData.bank.bankCode ||
        formData3.accountNumber !== profileData.bank.accountNumber ||
        data.name !== profileData.name ||
        data.profilePhotoLink !== profileData.profilePhotoLink;
      if (data.file) {
        payload = new FormData();
        payload.append('firstname', formData1.firstname);
        payload.append('lastname', formData1.lastname);
        payload.append('phoneNumber', `+250${formData1.phoneNumber}`);
        payload.append('nationalIdCard', formData2.nationalIdCard);
        payload.append('bank', JSON.stringify(bankObj));
        payload.append('name', data.name);
        payload.append('profilePhotoLink', data.profilePhotoLink);
        payload.append('contract', data.file);
      }
      if (check && !data.file && profileData.contract) {
        payload = new FormData();
        payload.append('firstname', formData1.firstname);
        payload.append('lastname', formData1.lastname);
        payload.append('phoneNumber', `+250${formData1.phoneNumber}`);
        payload.append('nationalIdCard', formData2.nationalIdCard);
        payload.append('bank', JSON.stringify(bankObj));
        payload.append('name', data.name);
        payload.append('profilePhotoLink', data.profilePhotoLink);
        payload.append('contract', profileData.contract);
      }
      if (check && !data.file && !profileData.contract) {
        payload = new FormData();
        payload.append('firstname', formData1.firstname);
        payload.append('lastname', formData1.lastname);
        payload.append('phoneNumber', `+250${formData1.phoneNumber}`);
        payload.append('nationalIdCard', formData2.nationalIdCard);
        payload.append('bank', JSON.stringify(bankObj));
        payload.append('name', data.name);
        payload.append('profilePhotoLink', data.profilePhotoLink);
      }

      if (payload) {
        updateAction(payload, 'update');
      } else {
        return setError('Error: Please change any thing, to update!');
      }
      setLoading(true);
      setError('');
    }
    return undefined;
  };

  const handlePrev = (data) => {
    if (step === 2 && data !== null) {
      setFormData2(data);
      setStep(step - 1);
    }
    if (step === 3 && data !== null) {
      setFormData3(data);
      setStep(step - 1);
    }
    if (step === 4 && data !== null) {
      setFormData4(data);
      setStep(step - 1);
    } else {
      setStep(step - 1);
    }
  };

  const currentComponent = (val) => {
    switch (val) {
      case 1:
        return (
          <PersonalInfo
            onNext={handleNext}
            onPrev={handlePrev}
            step={step}
            type="update"
            formData={formData1}
            profileData={profileData}
          />
        );
      case 2:
        return (
          <Identification
            onNext={handleNext}
            onPrev={handlePrev}
            step={step}
            type="update"
            formData={formData2}
            profileData={profileData}
          />
        );
      case 3:
        return (
          <BankInfo
            onNext={handleNext}
            onPrev={handlePrev}
            step={step}
            banksData={banksData}
            loading={loading}
            type="update"
            formData={formData3}
            profileData={profileData}
          />
        );
      case 4:
        return (
          <ArtistInfo
            onNext={handleNext}
            onPrev={handlePrev}
            step={step}
            formData={formData4}
            loading={loading}
            type="update"
            profileData={profileData}
          />
        );
      default:
        return <></>;
    }
  };

  const DisplayData = ({ children }) => {
    let data;
    switch (status) {
      case 'success':
        data = <>{children}</>;
        break;
      case 'fetching':
        data = <Loader />;
        break;
      default:
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
    }
    return data;
  };

  const DisplayData2 = ({ children }) => {
    let data;
    switch (bankStatus) {
      case 'success':
        data = <>{children}</>;
        break;
      case 'fetching':
        data = <Loader />;
        break;
      case 'unknown_error':
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
        break;
      default:
        data = <Loader />;
    }
    return data;
  };

  return (
    <>
      <DisplayData>
        <h5 className="text-center text-white">Update Profile</h5>
        {profileData ? (
          <div className="responsive-alert mt-4">
            <div
              className="alert alert-warning py-2 font-smooth"
              role="alert"
              style={{ marginTop: '-2px', marginRight: '-12px', fontSize: 15 }}
            >
              {profileData.status === 'pending'
                ? 'Your application is pending and is yet to be reviewed soon!'
                : 'Your previous application was denied, Please fix the issues mentioned in the email!'}
              <button
                type="button"
                className="close"
                style={{ marginTop: '-23px', marginRight: '-12px' }}
                data-dismiss="alert"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        ) : null}
        <SignUpNav step={step} status="in-progress" />
        <DisplayData2>{currentComponent(step)}</DisplayData2>
        <p className="text-center text-danger" style={{ fontSize: 15 }}>
          {error}
        </p>
      </DisplayData>
    </>
  );
};

SignUp.propTypes = {
  updateArtist: PropTypes.objectOf(PropTypes.any).isRequired,
  getBanks: PropTypes.objectOf(PropTypes.any).isRequired,
  updateArtistAction: PropTypes.func.isRequired,
  fetchBanksAction: PropTypes.func.isRequired,
};

const mapStateToProps = ({ updateArtist, getBanks }) => ({
  updateArtist,
  getBanks,
});

export default connect(mapStateToProps, {
  updateArtistAction,
  fetchBanksAction,
})(withBackground(SignUp, 'tempProfile'));
