/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import artistPlaceholder from '../assets/images/music.png';

const MusicCard = ({ music, goToSet }) => {
  const [imageLoading, setImageLoading] = useState(true);

  const imgSrc = () => {
    if (imageLoading) {
      return artistPlaceholder;
    }
    if (music.picture) {
      return music.picture;
    }
    return artistPlaceholder;
  };

  return (
    <div className="col-lg-4 px-4 py-2">
      <div
        className="row py-2 text-white bg-secondary rounded cursor-pointer"
        onClick={() => goToSet()}
        onKeyPress={goToSet}
        role="link"
        tabIndex={0}
      >
        <div className="col-3 align-self-center text-left">
          <img
            className="rounded"
            width="70px"
            height="70px"
            style={{ objectFit: 'cover' }}
            src={imgSrc()}
            alt=""
            srcSet=""
            onLoad={() => setImageLoading(false)}
          />
        </div>
        <div className="col-9 text-left pl-2">
          <p className="my-0 py-0">{music.name}</p>
          <p className="my-0 py-0" style={{ color: '#585858' }}>
            {music.type}
          </p>
          <p className="my-0 py-0">
            {music.monthlyStreams.toLocaleString()} Total,{' '}
            {music.totalStreams.toLocaleString()} This Month
          </p>
        </div>
      </div>
    </div>
  );
};

MusicCard.propTypes = {
  music: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
  goToSet: PropTypes.func.isRequired,
};

export default MusicCard;
