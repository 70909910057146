import signup from './signup';
import user from './user';
import resetPassword from './resetPassword';
import changePassword from './changePassword';
import newPassword from './newPassword';

export default {
  signup,
  user,
  resetPassword,
  changePassword,
  newPassword,
};
