import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import withSidebar from '../withSidebar';
import NumberCard from '../components/NumberCard';
import StreamsChart from '../components/Charts/ArtistStreams';
import MusicCard from '../components/MusicCard';
import AddSet from '../components/AddSet';
import Loader from '../components/Loader';
import Error from '../components/Error';
import artistPlaceholder from '../assets/images/avat1.png';
import fetchProfile from '../redux/actions/user/fetchProfile';

const Artist = ({ profile }) => {
  const history = useHistory();
  const [imageLoading, setImageLoading] = useState(true);
  const [status, setStatus] = useState('success');
  const [profileData, setProfileData] = useState(profile);

  useEffect(() => {
    if (!localStorage.getItem('INGOMA_ARTIST_TOKEN')) {
      return history.push('/login');
    }

    return undefined;
  }, []);

  const refetch = () => {
    setStatus('fetching');
    const initiate = async () => {
      try {
        const profData = await fetchProfile();
        setProfileData(profData);
        setStatus('success');
      } catch (error) {
        setStatus('error');
      }
    };
    initiate();
  };

  const DisplayData = ({ children }) => {
    let data;
    switch (status) {
      case 'success':
        data = <>{children}</>;
        break;
      case 'fetching':
        data = <Loader />;
        break;
      case 'no_data':
        data = (
          <Error
            title="No data found!"
            description="The artist does not exist!"
          />
        );
        break;
      default:
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
    }
    return data;
  };

  const imgSrc = () => {
    if (imageLoading) {
      return artistPlaceholder;
    }
    if (profileData.profilePhotoLink) {
      return profileData.profilePhotoLink;
    }
    return artistPlaceholder;
  };

  return (
    <>
      <div className="row mt-2">
        <NumberCard
          col={4}
          count={profile.monthlyStreams}
          body="Streams This Month"
        />
        <NumberCard col={4} count={profile.totalStreams} body="Total Streams" />
      </div>
      <div className="py-3">
        <div className="row">
          <DisplayData>
            <div className="col-3 text-center text-white">
              <img
                className="img-fluid rounded my-3"
                src={imgSrc()}
                style={{ height: '300px', width: '300px', objectFit: 'cover' }}
                alt=""
                srcSet=""
                onLoad={() => setImageLoading(false)}
              />
              <h4 className="my-0">{profileData.name}</h4>
              <p>Artist</p>
            </div>
          </DisplayData>
          <div className="col-9">
            <StreamsChart />
          </div>
        </div>
      </div>
      <DisplayData>
        <div className="py-3 text-white">
          <div className="row">
            <div className="col text-left my-2">
              <h4>Music</h4>
            </div>
            <div className="col text-right">
              <button
                type="button"
                className="btn btn-primary px-3 py-1 my-2"
                data-toggle="modal"
                data-target="#musicModal"
              >
                + Add Music
              </button>
              <AddSet refetch={refetch} />
            </div>
          </div>
          <div className="row">
            {profileData.musicSet.length ? (
              profileData.musicSet.map((set) => (
                <MusicCard
                  key={set._id}
                  music={set}
                  goToSet={() => history.push(`/sets/${set._id}`)}
                />
              ))
            ) : (
              <Error
                title="No data found!"
                description="No music sets added yet!"
              />
            )}
          </div>
        </div>
      </DisplayData>
    </>
  );
};

Artist.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withSidebar(Artist, 'music');
