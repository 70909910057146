export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const CLEAR_SIGNUP = 'CLEAR_SIGNUP';
export const GET_URL_SUCCESS = 'GET_URL_SUCCESS';
export const GET_URL_FAILED = 'GET_URL_FAILED';
export const CLEAR_GET_URL = 'CLEAR_GET_URL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const ARTIST_LOGOUT = 'ARTIST_LOGOUT';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';
export const CLEAR_FORGOT_PASSWORD = 'CLEAR_FORGOT_PASSWORD';
export const FETCH_POPULAR_SUCCESS = 'FETCH_POPULAR_SUCCESS';
export const FETCH_POPULAR_FAILED = 'FETCH_POPULAR_FAILED';
export const ADD_TRACK_SUCCESS = 'ADD_TRACK_SUCCESS';
export const ADD_TRACK_FAILED = 'ADD_TRACK_FAILED';
export const CLEAR_ADD_TRACK = 'CLEAR_ADD_TRACK';
export const CLEAR_STREAMS = 'CLEAR_STREAMS';
export const FETCH_STREAMS_SUCCESS = 'FETCH_TOTAL_STREAMS_SUCCESS';
export const FETCH_STREAMS_FAILED = 'FETCH_TOTAL_STREAMS_FAILED';
export const CLEAR_ARTIST = 'CLEAR_ARTIST';
export const FETCH_ARTIST_SUCCESS = 'FETCH_ARTIST_SUCCESS';
export const FETCH_ARTIST_FAILED = 'FETCH_ARTIST_FAILED';
export const UPDATE_ARTIST_SUCCESS = 'UPDATE_ARTIST_SUCCESS';
export const UPDATE_ARTIST_FAILED = 'UPDATE_ARTIST_FAILED';
export const CLEAR_UPDATE_ARTIST = 'CLEAR_UPDATE_ARTIST';
export const ADD_SET_SUCCESS = 'ADD_SET_SUCCESS';
export const ADD_SET_FAILED = 'ADD_SET_FAILED';
export const CLEAR_ADD_SET = 'CLEAR_ADD_SET';
export const CLEAR_SET = 'CLEAR_SET';
export const FETCH_SET_SUCCESS = 'FETCH_SET_SUCCESS';
export const FETCH_SET_FAILED = 'FETCH_SET_FAILED';
export const RELEASE_SUCCESS = 'RELEASE_SUCCESS';
export const RELEASE_FAILED = 'RELEASE_FAILED';
export const CLEAR_RELEASE = 'CLEAR_RELEASE';
export const UPDATE_SET_SUCCESS = 'UPDATE_SET_SUCCESS';
export const UPDATE_SET_FAILED = 'UPDATE_SET_FAILED';
export const CLEAR_UPDATE_SET = 'CLEAR_UPDATE_SET';
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS';
export const FETCH_PAYMENTS_FAILED = 'FETCH_PAYMENTS_FAILED';
export const CLEAR_PAYMENTS = 'CLEAR_PAYMENTS';
export const FETCH_BANKS_SUCCESS = 'FETCH_BANKS_SUCCESS';
export const FETCH_BANKS_FAILED = 'FETCH_BANKS_FAILED';
export const CLEAR_BANKS = 'CLEAR_BANKS';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS';
export const NEW_PASSWORD_FAILED = 'NEW_PASSWORD_FAILED';
export const GET_PAID_SUCCESS = 'GET_PAID_SUCCESS';
export const GET_PAID_FAILED = 'GET_PAID_FAILED';
export const CLEAR_GET_PAID = 'CLEAR_GET_PAID';
