/* eslint-disable object-curly-newline */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../components/Loader';
import Error from '../components/Error';
import withSidebar from '../withSidebar';
import NumberCard from '../components/NumberCard';
import Table from '../components/Tables/PayoutsTable';
import fetchPaymentsAction from '../redux/actions/payments/fetchPayments';
import GetPaid from '../components/modals/GetPaid';

const Payouts = ({
  fetchPaymentsAction: fetchPayments,
  getPayments,
  profile: { thisMonthRevenue, totalRevenue, lastMonthRevenue, balance, bank },
}) => {
  const history = useHistory();
  const [payouts, setPayouts] = useState([]);
  const [status, setStatus] = useState('initial');
  const [currentBal, setCurrentBal] = useState('');

  useEffect(() => {
    if (!localStorage.getItem('INGOMA_ARTIST_TOKEN')) {
      return history.push('/login');
    }
    if (status === 'initial') {
      fetchPayments();
      setStatus('fetching');
    }

    if (getPayments.status === 'clear') {
      setStatus('fetching');
      setPayouts([]);
    }

    if (getPayments.status === 'success') {
      setStatus('success');
      setPayouts(getPayments.results);
    }

    if (getPayments.status === 'error') {
      const { error } = getPayments;
      if (error.status === 500) setStatus('unknown_error');
      if (error.status === 404) setStatus('no_data');
    }

    return undefined;
  }, [getPayments]);

  const DisplayData = ({ children }) => {
    let data;
    switch (status) {
      case 'success':
        data = <>{children}</>;
        break;
      case 'fetching':
        data = <Loader />;
        break;
      case 'no_data':
        data = (
          <Error
            title="No data found!"
            description="Your payouts data will appear here."
          />
        );
        break;
      default:
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
    }
    return data;
  };

  const refetch = () => fetchPayments();

  const updateBalance = (val) => {
    setCurrentBal(val);
  };

  const generateBal = currentBal ? balance - currentBal : balance;
  const bankInfo = bank;

  return (
    <div>
      <div className="row mt-2">
        <NumberCard
          col={4}
          count={thisMonthRevenue}
          body="Revenue This Month"
        />
        <NumberCard
          col={4}
          count={lastMonthRevenue}
          body="Revenue Last Month"
        />
        <NumberCard col={4} count={totalRevenue} body="Total Revenue" />
      </div>
      <div className="mt-4">
        <div className="row">
          <div className="col-md-5">
            <button
              className="btn btn-primary px-4 py-1 ml-2"
              disabled={status === 'fetching' || generateBal < 5000}
              type="button"
              data-toggle="modal"
              data-target="#getPaidModal"
            >
              Get Paid
            </button>
            <GetPaid
              bank={bankInfo.bankCode}
              refetch={refetch}
              updateBalance={updateBalance}
            />
          </div>
          <div className="col-md-6">
            <p
              className="mt-2 text-white ml-2 text-right"
              style={{ fontSize: 15.5 }}
            >
              <span className="text-primary mr-1">
                <FontAwesomeIcon
                  icon={['fas', 'money-bill-alt']}
                  className="mr-2"
                />
                Balance:
              </span>
              {`${generateBal.toLocaleString()} RWF`}
            </p>
          </div>
        </div>

        <p className="mt-2 text-white ml-2" style={{ fontSize: 14 }}>
          <span className="text-primary mr-1">N.B:</span>
          You can only request payout when balance is above 5000 RWF.
        </p>
      </div>
      <DisplayData>
        <Table data={payouts} />
      </DisplayData>
    </div>
  );
};

Payouts.propTypes = {
  fetchPaymentsAction: PropTypes.func.isRequired,
  getPayments: PropTypes.objectOf(PropTypes.any).isRequired,
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateProps = ({ getPayments }) => ({ getPayments });

export default connect(mapStateProps, {
  fetchPaymentsAction,
})(withSidebar(Payouts, 'payouts'));
