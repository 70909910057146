/* eslint-disable arrow-body-style */
import React from 'react';
import { Link } from 'react-router-dom';
import logoRound from './assets/images/logo-round.png';

const withBackground = (Component, type) => (props) => {
  const authNav = () => {
    switch (type) {
      case 'signup':
        return (
          <div className="auth-nav">
            <Link to="/login">Already have an account? Login Instead</Link>
          </div>
        );
      case 'login':
        return (
          <div className="auth-nav">
            <Link to="/signup">New to Ingoma? Create your account</Link>
          </div>
        );
      case 'forgot-password':
        return (
          <div className="auth-nav">
            <Link to="/login">Go back to login</Link>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div
      className={
        type === 'signup' || type === 'tempProfile'
          ? 'auth-page for-signup'
          : 'auth-page'
      }
      style={{ paddingTop: type === 'tempProfile' ? '80px' : 0 }}
    >
      {authNav()}
      <div className="auth-page-content pb-3">
        <img src={logoRound} alt="round-logo" className="image-logo-round" />
        <h3 className="text-center text-primary">INGOMA</h3>
        <Component {...props} />
      </div>
    </div>
  );
};

export default withBackground;
