/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable object-curly-newline */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const StepButtons = ({
  onNext,
  onPrev,
  disableBtn,
  disableNext,
  step,
  loading,
  type,
}) => {
  const nextBtn = () => {
    const btnText = type === 'update' ? 'Update' : 'Sign Up';
    if (step === 4) {
      if (loading) {
        return (
          <span>
            {btnText}
            <span className="spinner-border spinner-border-sm text-dark ml-2" />
          </span>
        );
      }
      return <span>{btnText}</span>;
    }
    return (
      <>
        <span>Next</span>
        <FontAwesomeIcon
          icon={['fas', 'chevron-right']}
          className="auth-form-icon ml-2"
        />
      </>
    );
  };
  return (
    <div
      className="form-btn-div mx-auto"
      style={{ width: '80%', paddingBottom: 35 }}
    >
      <div className="row">
        <div className="text-left col-6">
          <button
            className="btn btn-outline-primary bank-info-prev py-1 mt-3"
            type="button"
            onClick={onPrev}
            disabled={!!(loading || disableBtn)}
          >
            <FontAwesomeIcon
              icon={['fas', 'chevron-left']}
              className="auth-form-icon mr-2"
            />
            Previous
          </button>
        </div>
        <div className="text-right col-6">
          <button
            className="btn btn-primary bank-info-prev py-1 mt-3 px-4"
            type="button"
            onClick={onNext}
            disabled={!!(loading || disableNext)}
          >
            {nextBtn()}
          </button>
        </div>
      </div>
    </div>
  );
};

StepButtons.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  disableBtn: PropTypes.bool.isRequired,
  disableNext: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  type: PropTypes.string,
  step: PropTypes.number.isRequired,
};

StepButtons.defaultProps = {
  loading: null,
  type: '',
};

export default StepButtons;
