/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import musicPic from '../../assets/images/musicSvg.png';
import StepButtons from './StepButtons';
import validator from '../../helpers/validator';

const SignUp = ({
  onNext,
  onPrev,
  step,
  formData,
  banksData,
  type,
  profileData,
}) => {
  const bankInfo = type === 'update' ? profileData.bank : {};
  const [bank, setBank] = useState(
    type === 'update' && !formData.bankCode
      ? bankInfo.bankCode
      : formData.bankCode
  );
  const [accountNumber, setAccountNumber] = useState(
    type === 'update' && !formData.accountNumber
      ? bankInfo.bankCode === 'MPS'
        ? bankInfo.accountNumber.slice(3, bankInfo.accountNumber.length)
        : bankInfo.accountNumber
      : formData.accountNumber
  );
  const [bankName, setBankName] = useState(
    type === 'update' && !formData.bankName
      ? bankInfo.bankName
      : formData.bankName
  );
  const [errors, setErrors] = useState([]);
  const [isStepValid, setIsStepValid] = useState(false);

  // const getValidationErrors = async () => {
  //   if (!bank || !accountNumber || !bankName) {
  //     return ['All fields are required'];
  //   }
  //   if (bank === 'MPS') return validator({ accountNumber });

  //   return [];
  // };

  const getValidationErrors = async () => validator({ accountNumber });

  useEffect(() => {
    async function fetchErrors() {
      const validationErrors = await getValidationErrors();
      setIsStepValid(!validationErrors.length);
      setErrors(validationErrors.length ? validationErrors : []);
    }

    fetchErrors();
  }, [
    type === 'update' ? null : bank,
    type === 'update' ? null : accountNumber,
    type === 'update' ? null : bankName,
  ]);

  const handleBankChange = (e) => {
    const val = e.target.value;
    const filterBank = banksData.filter((elt) => elt.code === val);
    const { name } = filterBank[0];
    setBank(val);
    setBankName(name);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      bankCode: bank,
      bankName,
      accountNumber,
    };

    const validationErrors = getValidationErrors();
    if (validationErrors.length) return setErrors(validationErrors);

    // if (!bank || !accountNumber || !bankName) {
    //   return setErrors(['All fields are required']);
    // }

    return onNext(data);
  };

  const handlePrev = async (e) => {
    e.preventDefault();
    if (bank && accountNumber && bankName) {
      const data = {
        bankCode: bank,
        bankName,
        accountNumber,
      };
      return onPrev(data);
    }

    return onPrev(null);
  };

  return (
    <>
      <div className="register-content mx-auto mt-3">
        <div className="row">
          <div className="col-md-6 div-1">
            <div className="auth-form">
              <p className="text-white mt-4">
                Please provide the bank and account number payouts will be made
                to.
              </p>
              <form className="mt-3 bank-info-form">
                {errors.map((error, index) => (
                  <div
                    className="alert alert-warning py-2 font-smooth"
                    role="alert"
                    key={index}
                    style={{ fontSize: 15 }}
                  >
                    {error}
                    <button
                      type="button"
                      className="close"
                      style={{ marginTop: '-2px', marginRight: '-12px' }}
                      onClick={() => setErrors([])}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ))}
                <div className="form-row">
                  <div className="form-group col-12 mb-4">
                    {/* <div className="styled-select">
                      <select
                        className="form-control yellow-input"
                        value={bank}
                        onChange={handleBankChange}
                      >
                        <option disabled defaultValue value={0}>
                          Choose Bank
                        </option>
                        {banksData.map((elt) => (
                          <option value={elt.code} key={elt.id}>
                            {elt.name}
                          </option>
                        ))}
                      </select>
                    </div> */}
                    <input
                      type="text"
                      className="form-control yellow-input"
                      placeholder="Enter Bank"
                      value="Mobile Money"
                      disabled
                    />
                  </div>
                  <div className="form-group col-12 mb-4">
                    {/* {bank === 'MPS' ? (
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">+250</span>
                        </div>
                        <input
                          type="text"
                          className="form-control yellow-input"
                          placeholder="Phone Number"
                          value={accountNumber}
                          onChange={(e) => {
                            setAccountNumber(e.target.value);
                          }}
                          maxLength="9"
                        />
                      </div>
                    ) : (
                      <input
                        type="text"
                        className="form-control yellow-input"
                        placeholder="Account Number"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                      />
                    )} */}
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">+250</span>
                      </div>
                      <input
                        type="text"
                        className="form-control yellow-input"
                        placeholder="Phone Number"
                        value={accountNumber}
                        onChange={(e) => {
                          setAccountNumber(e.target.value);
                        }}
                        maxLength="9"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6 div-2">
            {type === 'update' ? (
              <div
                className="alert alert-warning py-2 font-smooth"
                role="alert"
                style={{ fontSize: 15 }}
              >
                {profileData.status === 'pending'
                  ? 'Your application is pending and is yet to be reviewed soon!'
                  : 'Your previous application was denied, Please fix the issues mentioned in the email!'}
                <button
                  type="button"
                  className="close"
                  style={{ marginTop: '-2px', marginRight: '-12px' }}
                  data-dismiss="alert"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ) : null}
            <div className="form-img-div">
              <img
                src={musicPic}
                alt="right-form-pic"
                className="right-form-image"
              />
            </div>
          </div>
        </div>
      </div>
      <StepButtons
        onNext={handleSubmit}
        onPrev={handlePrev}
        step={step}
        disableBtn={false}
        disableNext={!isStepValid}
      />
    </>
  );
};

SignUp.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  banksData: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    )
  ).isRequired,
  profileData: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
};

SignUp.defaultProps = {
  profileData: {},
  type: '',
};

export default SignUp;
