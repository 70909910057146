/* eslint-disable no-useless-catch */
import axios, { config, configTemp } from '..';

export default async (type) => {
  try {
    const response = await axios.get(
      '/artists/my-profile',
      type === 'temp' ? configTemp : config
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
