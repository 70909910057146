import React from 'react';
import { Link } from 'react-router-dom';
import withBackground from '../withBackground';
import phoneSuccess from '../assets/images/phoneSuccess.png';
import vector1 from '../assets/images/vector1.png';
import SignUpNav from '../components/forms/SignUpNav';

const SignUpSuccess = () => (
  <>
    <h5 className="text-center text-white">Update Profile</h5>
    <SignUpNav step={5} />
    <div className="register-content success-content mx-auto mt-3 pb-3">
      <div className="row">
        <div className="col-md-6 div-1">
          <div className="auth-form">
            <h3 className="text-white font-weight-bold">
              Profile Updated Successfully
            </h3>
            <p className="text-white" style={{ marginTop: 30 }}>
              Your current profile have been submitted! We are currently
              reviewing the submitted info and we’ll reach out ASAP.
            </p>

            <Link className="btn btn-primary py-1 mt-4 px-4" to="/login">
              Take me to Login
            </Link>
          </div>
        </div>
        <div className="col-md-6 div-2">
          <div className="form-cover-img-div">
            <img src={vector1} alt="vector-pic" className="vector-1-image" />
            <div className="sm-form-cover-div">
              <img
                src={phoneSuccess}
                alt="phone-success-pic"
                className="phone-success-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default withBackground(SignUpSuccess);
