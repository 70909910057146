import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import validator from '../helpers/validator';
import loginAction from '../redux/actions/user/login';
import withBackground from '../withBackground';
import musicPic from '../assets/images/musicSvg.png';

const Login = ({ user, loginAction: login }) => {
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('INGOMA_ARTIST_TOKEN')) return history.push('/');
    if (localStorage.getItem('INGOMA_TEMP_ARTIST_TOKEN')) {
      return localStorage.removeItem('INGOMA_TEMP_ARTIST_TOKEN');
    }
    setSubmitting(false);

    if (user.status === 'login_success') {
      if (user.apprStatus === 'verified') {
        localStorage.setItem('INGOMA_ARTIST_TOKEN', user.token);
        return window.location.assign('/');
      }
      localStorage.setItem('INGOMA_TEMP_ARTIST_TOKEN', user.token);
      return window.location.assign('application/update');
    }

    if (user.status === 'login_error') {
      setSubmitting(false);
      return setErrors([user.error.message]);
    }
    return undefined;
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email,
      password,
    };
    const validationErrors = await validator({ email });
    if (validationErrors.length > 0) return setErrors(validationErrors);
    setErrors([]);
    setSubmitting(true);
    return login(data);
  };

  return (
    <>
      <div className="register-content mx-auto mt-3 pb-3">
        <div className="row">
          <div className="col-md-6 div-1">
            <div className="auth-form">
              <h3 className="text-white font-weight-bold">Log In</h3>
              <p className="text-white" style={{ marginTop: 20 }}>
                You need to login to access your account.
              </p>
              <form className="mt-3">
                {errors.map((error, index) => (
                  <div
                    className="alert alert-warning py-2 font-smooth"
                    role="alert"
                    key={index}
                    style={{ fontSize: 15 }}
                  >
                    {error}
                    <button
                      type="button"
                      className="close"
                      onClick={() => setErrors([])}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ))}
                <div className="form-row">
                  <div className="form-group col-12">
                    <div className="small-form-input-div">
                      <input
                        type="text"
                        className="form-control yellow-input"
                        placeholder="E-mail"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group col-12">
                    <div className="small-form-input-div">
                      <input
                        type="password"
                        className="form-control yellow-input"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <Link
                    className="py-1 mt-1"
                    style={{ fontSize: 15 }}
                    to="/reset-password"
                  >
                    Forgot Password?
                  </Link>
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-primary py-1 mt-3 px-5"
                    type="button"
                    onClick={handleSubmit}
                    disabled={!!(submitting || !email || !password)}
                  >
                    {submitting ? (
                      <span>
                        Log In
                        <span className="spinner-border spinner-border-sm text-dark ml-2" />
                      </span>
                    ) : (
                      <span>Log In</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6 div-2">
            <div className="form-img-div">
              <img
                src={musicPic}
                alt="right-form-pic"
                className="right-form-image"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Login.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  loginAction: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps, {
  loginAction,
})(withBackground(Login, 'login'));
