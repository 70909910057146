import axios, { config } from '..';
import {
  FETCH_STREAMS_SUCCESS,
  FETCH_STREAMS_FAILED,
  CLEAR_STREAMS,
} from '../../actionTypes';

export default (type, id, period = []) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_STREAMS });

    let url;
    switch (type) {
      case 'my-streams':
        url = '/streams/stats';
        break;
      case 'artist':
        url = '/streams/stats';
        break;
      case 'set':
        url = `/streams/music-sets/${id}/stats`;
        break;
      default:
        throw Error('A type needs to be passed');
    }

    let body = {};
    const [fromYear, fromMonth, toYear, toMonth] = period;
    if (fromYear && fromMonth && toYear && toMonth) {
      body = {
        fromYear,
        fromMonth,
        toYear,
        toMonth,
      };
    }

    const response = await axios.post(url, body, config);
    const {
      data: { message, data },
    } = response;
    dispatch({
      type: FETCH_STREAMS_SUCCESS,
      message,
      results: data,
    });
  } catch (err) {
    let error = {};
    if (err.response) {
      const {
        data: { status, message },
      } = err.response;
      error = { status, message };
    } else {
      error = {
        status: 500,
        message: err.message,
      };
    }
    dispatch({ type: FETCH_STREAMS_FAILED, error });
  }
};
