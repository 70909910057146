import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withBackground from '../withBackground';
import phoneSuccess from '../assets/images/mailSent.png';
import vector2 from '../assets/images/starVector.png';
import clearSuccessPageAction from '../redux/actions/user/clearSuccessPage';

const ResetPwdSuccess = ({ clearSuccessPageAction: clearAction }) => {
  useEffect(() => {
    clearAction('reset');
    return undefined;
  });
  return (
    <>
      <div className="register-content mx-auto mt-4 pb-4">
        <div className="row">
          <div className="col-6 div-1">
            <div className="auth-form mt-5">
              <h3 className="text-white font-weight-bold">Reset Password</h3>
              <p className="text-white" style={{ marginTop: 30 }}>
                Please check your inbox for a password reset link.
              </p>

              <Link className="btn btn-primary py-1 mt-4 px-4" to="/login">
                Login
              </Link>
            </div>
          </div>
          <div className="col-6 div-2">
            <div className="form-cover-img-div">
              <img src={vector2} alt="vector-pic" className="vector-1-image" />
              <div className="sm-form-cover-div">
                <img
                  src={phoneSuccess}
                  alt="phone-success-pic"
                  className="phone-success-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ResetPwdSuccess.propTypes = {
  clearSuccessPageAction: PropTypes.func.isRequired,
};
export default connect(null, {
  clearSuccessPageAction,
})(withBackground(ResetPwdSuccess));
