/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../usage/Icon';

const SignUpNav = ({ step }) => {
  const data = {
    outerClass: (val) =>
      step >= val
        ? 'form-nav-indicator text-white font-weight-bold'
        : 'form-nav-indicator text-white font-weight-bold disable-indicator',
    innerClass: (val) =>
      step > val
        ? 'mr-2 text-primary number-indicator number-indicator-border'
        : 'number-indicator mr-2 text-primary',
    value: (val) =>
      step > val ? (
        <Icon name="success" height={18} width={18} color="#e5da2f" />
      ) : (
        val
      ),
    isCompleted: (val) =>
      step > val ? 'col-md-3 text-center completed' : 'col-md-3 text-center',
  };

  return (
    <div className="signup-form-nav mx-auto mt-4">
      <div className="row">
        <span className={data.isCompleted(1)}>
          <span className={data.outerClass(1)}>
            <span className={data.innerClass(1)}>{data.value(1)}</span>
            Personal Information
          </span>
        </span>
        <span className={data.isCompleted(2)}>
          <span className={data.outerClass(2)}>
            <span className={data.innerClass(2)}>{data.value(2)}</span>
            Identification
          </span>
        </span>
        <span className={data.isCompleted(3)}>
          <span className={data.outerClass(3)}>
            <span className={data.innerClass(3)}>{data.value(3)}</span>
            Bank Information
          </span>
        </span>
        <span className={data.isCompleted(4)}>
          <span className={data.outerClass(4)}>
            <span className={data.innerClass(4)}>{data.value(4)}</span>
            Artist Information
          </span>
        </span>
      </div>
    </div>
  );
};

SignUpNav.propTypes = {
  step: PropTypes.number.isRequired,
};

export default SignUpNav;
