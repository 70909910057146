/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import updateArtistAction from '../redux/actions/artists/updateArtist';
import fetchBanksAction from '../redux/actions/payments/fetchBanks';
import Loader from './Loader';
import Error from './Error';
import validator from '../helpers/validator';

const PaymentInfo = ({
  provider,
  updateArtistAction: updateArtist,
  fetchBanksAction: fetchBanks,
  updateArtist: updatedArtist,
  getBanks,
}) => {
  const [status, setStatus] = useState('initial');
  const [submitStatus, setSubmitStatus] = useState('');
  const [errors, setErrors] = useState([]);
  const [currentAccNumber, setCurrentAccNumber] = useState('');
  const [currentAccBank, setCurrentAccBank] = useState('');
  const [accountBank, setAccountBank] = useState(0);
  const [accountBankName, setAccountBankName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [banksData, setBanksData] = useState([]);

  useEffect(() => {
    if (status === 'initial') {
      const bankInfo = provider.bank;
      setCurrentAccNumber(
        bankInfo.bankCode === 'MPS'
          ? bankInfo.accountNumber.slice(3, bankInfo.accountNumber.length)
          : bankInfo.accountNumber
      );
      setAccountNumber(
        bankInfo.bankCode === 'MPS'
          ? bankInfo.accountNumber.slice(3, bankInfo.accountNumber.length)
          : bankInfo.accountNumber
      );
      setCurrentAccBank(bankInfo.bankCode);
      setAccountBank(bankInfo.bankCode);
      setAccountBankName(bankInfo.bankName);
      fetchBanks();
      setStatus('fetching');
    }

    if (updatedArtist.status === 'clearEdit_artist') {
      setSubmitStatus('');
    }

    if (updatedArtist.status === 'error') {
      setSubmitStatus('failed');
      return setErrors([updatedArtist.error.message]);
    }

    if (updatedArtist.status === 'success') {
      setSubmitStatus('success');
      const bankInfo = updatedArtist.results.bank;
      setCurrentAccNumber(
        bankInfo.bankCode === 'MPS'
          ? bankInfo.accountNumber.slice(3, bankInfo.accountNumber.length)
          : bankInfo.accountNumber
      );
      setCurrentAccBank(bankInfo.bankCode);
    }
    if (getBanks.status === 'error') {
      return setStatus('unknown_error');
    }

    if (getBanks.status === 'success') {
      setBanksData(getBanks.results);
      return setStatus('fetch_success');
    }
    return undefined;
  }, [updatedArtist, getBanks]);

  const handleBankChange = (e) => {
    const val = e.target.value;
    const filterBank = banksData.filter((elt) => elt.code === val);
    const { name } = filterBank[0];
    setAccountBank(val);
    setAccountBankName(name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();

    // if (!accountBank || !accountNumber) {
    //   return setErrors(['All fields are required!']);
    // }

    // if (accountBank === 'MPS') {
    //   const validationErrors = await validator({ accountNumber });
    //   if (validationErrors.length > 0) return setErrors(validationErrors);
    // }

    const validationErrors = await validator({ accountNumber });
    if (validationErrors.length > 0) return setErrors(validationErrors);

    // const bankObj = {
    //   bankName: accountBankName,
    //   bankCode: accountBank,
    //   accountNumber:
    //     accountBank === 'MPS' ? `250${accountNumber}` : accountNumber,
    // };

    const bankObj = {
      bankName: 'Mobile Money',
      bankCode: 'MPS',
      accountNumber: `250${accountNumber}`,
    };

    data.append('bank', JSON.stringify(bankObj));
    setErrors([]);
    setSubmitStatus('submitting');
    return updateArtist(data);
  };

  return (
    <div className="col-6 mt-4">
      <div
        className="bg-secondary text-white pb-4 px-4 pt-3 rounded text-center"
        style={{ minHeight: '160px' }}
      >
        <h6>Update Payment Info</h6>
        {status === 'fetching' ? (
          <Loader />
        ) : status === 'unknown_error' ? (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        ) : (
          <form action="" className="mt-4">
            {errors.map((error, index) => (
              <div
                className="alert alert-warning alert-dismissible mx-auto medium-text w-75"
                role="alert"
                key={index}
              >
                {error}
                <button
                  type="button"
                  className="close"
                  onClick={() => setErrors([])}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ))}
            {submitStatus === 'success' ? (
              <div
                className="alert alert-warning alert-dismissible mx-auto medium-text w-75"
                role="alert"
              >
                Your Payment info has been updated successfully!
                <button
                  type="button"
                  className="close"
                  onClick={() => setSubmitStatus('loaded')}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ) : null}
            {/* <select
              value={accountBank}
              onChange={handleBankChange}
              className="custom-select text-white border-0 w-75 formSelect"
              disabled={submitStatus === 'submitting'}
            >
              <option disabled defaultValue value={0}>
                Choose Bank
              </option>
              {banksData.map((bank) => (
                <option value={bank.code} key={bank.id}>
                  {bank.name}
                </option>
              ))}
            </select> */}
            <input
              type="text"
              className="form-control formInput w-75 mt-3 mx-auto"
              placeholder="Enter bank"
              required
              value="Mobile Money"
              disabled
            />
            <div className="input-group w-75 mt-3 mx-auto ">
              <div className="input-group-prepend payment-info-grp">
                <span className="input-group-text">+250</span>
              </div>
              <input
                type="text"
                className={`form-control formInput ${
                  submitStatus === 'submitting' ? 'cursor-not-allowed' : ''
                }`}
                placeholder="Phone Number"
                value={accountNumber}
                onChange={(e) => {
                  setAccountNumber(e.target.value);
                }}
                maxLength="9"
                disabled={submitStatus === 'submitting'}
              />
            </div>
            {/* {accountBank === 'MPS' ? (
              <div className="input-group w-75 mt-3 mx-auto ">
                <div className="input-group-prepend payment-info-grp">
                  <span className="input-group-text">+250</span>
                </div>
                <input
                  type="text"
                  className={`form-control formInput ${
                    submitStatus === 'submitting' ? 'cursor-not-allowed' : ''
                  }`}
                  placeholder="Phone Number"
                  value={accountNumber}
                  onChange={(e) => {
                    setAccountNumber(e.target.value);
                  }}
                  maxLength="9"
                  disabled={submitStatus === 'submitting'}
                />
              </div>
            ) : (
              <input
                type="text"
                className={`form-control formInput w-75 mt-3 mx-auto ${
                  submitStatus === 'submitting' ? 'cursor-not-allowed' : ''
                }`}
                placeholder="Enter your Account number"
                required
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
                disabled={submitStatus === 'submitting'}
              />
            )} */}
            <div className="w-75 mx-auto mb-3">
              {submitStatus === 'submitting' ? (
                <button
                  type="submit"
                  className="btn btn-secondary py-1 text-center mt-4 w-50"
                  disabled
                >
                  UPDATING
                  <span
                    className="spinner-border spinner-border ml-2"
                    style={{
                      width: '1.5rem',
                      height: '1.5rem',
                      fontSize: '0.7rem',
                    }}
                    role="status"
                    aria-hidden="true"
                  />
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary py-1 text-center mt-4 w-50 mb-1"
                  disabled={currentAccNumber === accountNumber}
                  onClick={handleSubmit}
                >
                  UPDATE
                </button>
              )}
              {/* <button
                  type="submit"
                  className="btn btn-primary py-1 text-center mt-4 w-50 mb-1"
                  disabled={
                    currentAccNumber === accountNumber &&
                    currentAccBank === accountBank
                  }
                  onClick={handleSubmit}
                >
                  UPDATE
                </button> */}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

PaymentInfo.propTypes = {
  provider: PropTypes.objectOf(PropTypes.any).isRequired,
  updateArtistAction: PropTypes.func.isRequired,
  fetchBanksAction: PropTypes.func.isRequired,
  getBanks: PropTypes.objectOf(PropTypes.any).isRequired,
  updateArtist: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ updateArtist, getBanks }) => ({
  updateArtist,
  getBanks,
});

export default connect(mapStateToProps, {
  updateArtistAction,
  fetchBanksAction,
})(PaymentInfo);
