import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import validator from '../helpers/validator';
import newPasswordAction from '../redux/actions/user/newPassword';
import withBackground from '../withBackground';
import musicPic from '../assets/images/musicSvg.png';

const NewPassword = ({ newPasswordAction: reset, newPassword, location }) => {
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [resetSuccess, SetResetSuccess] = useState(false);

  const initialPage = location.search.indexOf('?token=') !== -1;
  const successPage = location.search.indexOf('?reset=success') !== -1;

  useEffect(() => {
    if (localStorage.getItem('INGOMA_ARTIST_TOKEN')) {
      localStorage.removeItem('INGOMA_ARTIST_TOKEN');
    }
    if (!initialPage && !(successPage && resetSuccess)) {
      return history.push('/login');
    }
    setSubmitting(false);
    if (newPassword.status === 'error') {
      return setErrors([newPassword.error.message]);
    }
    if (newPassword.status === 'success') {
      SetResetSuccess(true);
      return history.replace(`${location.pathname}?reset=success`);
    }
    return undefined;
  }, [newPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { password };
    const urlToken = location.search.slice(
      location.search.indexOf('?token=') + 7,
      location.search.length
    );
    const validationErrors = await validator({ ...data, urlToken });
    if (validationErrors.length > 0) return setErrors(validationErrors);
    setErrors([]);
    setSubmitting(true);
    return reset(data, urlToken);
  };

  return (
    <>
      <div className="register-content mx-auto mt-3 py-4">
        <div className="row">
          <div className="col-md-6 div-1">
            <div className="auth-form">
              <h3 className="text-white font-weight-bold">New Password</h3>
              <p className="text-white" style={{ marginTop: 20 }}>
                Please enter a new password to complete the reset process.
              </p>
              <div>
                {errors.map((error, index) => (
                  <div className="alert alert-warning" role="alert" key={index}>
                    {error}
                    <button
                      type="button"
                      className="close"
                      onClick={() => setErrors([])}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ))}
              </div>
              {resetSuccess ? (
                <>
                  <div className="alert alert-warning" role="alert">
                    Your Password has been successfully reset, Proceed to login
                    with your new password.
                  </div>
                  <p className="text-center my-4">
                    <Link className="btn btn-primary py-1 px-4" to="/login">
                      Go to Login
                    </Link>
                  </p>
                </>
              ) : (
                <form className="mt-3">
                  <div className="form-row">
                    <div className="form-group col-12 mt-4">
                      <div className="small-form-input-div">
                        <input
                          type="password"
                          className="form-control yellow-input"
                          placeholder="New Password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-primary py-1 mt-4 px-5"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={!!(submitting || !password)}
                    >
                      {submitting ? (
                        <span>
                          Resetting
                          <span className="spinner-border spinner-border-sm text-dark ml-2" />
                        </span>
                      ) : (
                        <span>Reset</span>
                      )}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
          <div className="col-md-6 div-2">
            <div className="form-img-div">
              <img
                src={musicPic}
                alt="right-form-pic"
                className="right-form-image"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

NewPassword.propTypes = {
  newPassword: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  newPasswordAction: PropTypes.func.isRequired,
};

const mapStateToProps = ({ newPassword }) => ({
  newPassword,
});

export default connect(mapStateToProps, {
  newPasswordAction,
})(withBackground(NewPassword));
