/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import artistPlaceholder from '../assets/images/avat1.png';

const TopTracks = ({ data = [] }) => {
  const [loading, setLoading] = useState(true);

  const imgSrc = (pic) => {
    if (loading) {
      return artistPlaceholder;
    }
    if (pic) {
      return pic;
    }
    return artistPlaceholder;
  };

  return (
    <>
      {data.map((popular) => (
        <div
          className="row mx-2 my-2 px-1 py-2 text-white bg-secondary rounded"
          key={popular._id.Track}
        >
          <div className="col-4 align-self-center">
            <img
              className="d-inline pr-3"
              width="60px"
              height="40px"
              src={imgSrc(popular.picture)}
              alt="Track_Image"
              srcSet=""
              onLoad={() => setLoading(false)}
            />
            <p className="d-inline px-3 my-0">{popular.title}</p>
          </div>
          <div className="col-4 align-self-center text-center">
            <p className="my-0">{popular.artists}</p>
          </div>
          <div className="col-4 align-self-center text-right">
            <p className="my-0">
              {`${
                popular.streams === 1
                  ? `${popular.streams.toLocaleString()} Stream`
                  : `${popular.streams.toLocaleString()} Streams`
              }`}
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

TopTracks.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any]))
  ).isRequired,
};

export default TopTracks;
