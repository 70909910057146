/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';

const Table = ({ data = [] }) => {
  const months = [
    null,
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const dateFind = (payDate) => {
    const d = new Date(payDate);
    return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
  };

  const timeFind = (payDate) => {
    const d = new Date(payDate);
    return `${d.getHours()}h:${d.getMinutes() + 1}`;
  };

  return (
    <>
      <div className="border rounded mt-4">
        <table className="table text-white text-center mb-0">
          <thead>
            <tr>
              <th scope="col">Transaction Date</th>
              <th scope="col">Time</th>
              <th scope="col">Month</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            {data.map((payout) => (
              <tr key={payout._id}>
                <td>{dateFind(payout.date)}</td>
                <td>{timeFind(payout.date)}</td>
                <td>{`${months[payout.month]}  ${payout.year}`}</td>
                <td>{payout.amount.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any]))
  ).isRequired,
};

export default Table;
