import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import $ from 'jquery';
import updateSetAction from '../redux/actions/sets/updateSet';
import Upload from './forms/Upload';
import clearRecentAction from '../redux/actions/upload/clearRecent';

const AddSet = ({
  updateSet: updatedSet,
  updateSetAction: updateSet,
  refetch,
  artist,
  setData,
  clearRecentAction: clearRecent,
}) => {
  const [status, setStatus] = useState('');
  const [errors, setErrors] = useState([]);
  const [img, setImg] = useState(null);
  const [name, setName] = useState('');
  const [currentImg, setCurrentImg] = useState(null);
  const [fileKey, setFileKey] = useState('');
  const [fileSaved, setFileSaved] = useState(false);

  useEffect(() => {
    setName(setData.name);
    setCurrentImg(setData.picture || null);
    if (updatedSet.status === 'success') {
      $('#updateSetModal').modal('hide');
      clearRecent();
      setStatus('');
      setFileKey('');
      setFileSaved(false);
      setErrors([]);
      setImg(null);
      refetch();
    }
    if (updatedSet.status === 'error') {
      setStatus('');
      return setErrors([updatedSet.error.message]);
    }
    return undefined;
  }, [updatedSet]);

  const updateErrors = (error) => {
    setErrors(error);
  };

  const updateFileKey = (key) => {
    setFileKey(key);
  };

  const updateFileSaved = (saved) => {
    setFileSaved(saved);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name) return setErrors(['All fields are required']);
    let payload;
    if (name !== setData.name) {
      payload = new FormData();
      payload.append('name', name);
    }

    if (fileKey) {
      payload = new FormData();
      payload.append('picture', fileKey);
    }

    if (payload) {
      updateSet(artist, payload);
    } else {
      return setErrors(['Please change any thing, to update!']);
    }

    setErrors([]);
    return setStatus('submitting');
  };

  return (
    <div
      className="modal fade"
      id="updateSetModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="updateSetModal"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content bg-secondary text-white border-0  pb-5">
          <div className="modal-header">
            <h6 className="modal-title mx-auto text-center" id="updateSetModal">
              Update Music Set
            </h6>
            <span
              data-dismiss="modal"
              className="close-button cursor-pointer text-center"
            >
              &times;
            </span>
          </div>
          <div className="modal-body">
            {errors.map((error, index) => (
              <div
                className="alert alert-warning text-center"
                role="alert"
                key={index}
              >
                {error}
                <button
                  type="button"
                  className="close"
                  onClick={() => setErrors([])}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ))}
            <div className="row">
              <div className="col-md-5">
                <Upload
                  defaultValue2={currentImg}
                  updateErrors={updateErrors}
                  updateFileSaved={updateFileSaved}
                  updateFileKey={updateFileKey}
                  uploadType="set"
                  changeValue
                  status={status}
                  id="updateSetFile"
                  accept=".png,.jpg"
                />
              </div>
              <div className="col-md-7">
                <form action="" className="">
                  <div className="form-group">
                    <input
                      type="text"
                      className={`form-control formInput ${
                        status === 'submitting' ? 'cursor-not-allowed' : ''
                      }`}
                      placeholder="Title"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      disabled={status === 'submitting'}
                    />
                  </div>
                  <div className="form-group mt-4">
                    <input
                      type="text"
                      className="form-control formInput cursor-not-allowed"
                      required
                      value={setData.type}
                      style={{ color: '#ff0' }}
                      disabled
                    />
                  </div>
                  <div className="form-group mt-4 text-center">
                    {status === 'submitting' ? (
                      <button
                        type="submit"
                        className="btn btn-secondary py-1 px-4"
                        disabled
                      >
                        Updating
                        <span
                          className="spinner-border spinner-border ml-2"
                          style={{
                            width: '1.2rem',
                            height: '1.2rem',
                            fontSize: '0.5rem',
                          }}
                          role="status"
                          aria-hidden="true"
                        />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary py-1 px-4"
                        onClick={handleSubmit}
                        disabled={
                          !!((!fileSaved && img) || (!fileKey && img) || !name)
                        }
                      >
                        Update Set
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AddSet.propTypes = {
  updateSet: PropTypes.objectOf(PropTypes.any).isRequired,
  setData: PropTypes.objectOf(PropTypes.any).isRequired,
  updateSetAction: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  clearRecentAction: PropTypes.func.isRequired,
  artist: PropTypes.string.isRequired,
};

const mapStateProps = ({ updateSet }) => ({ updateSet });

export default connect(mapStateProps, { updateSetAction, clearRecentAction })(
  AddSet
);
