import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import $ from 'jquery';
import updateArtistAction from '../redux/actions/artists/updateArtist';
import Upload from './forms/Upload';
import clearRecentAction from '../redux/actions/upload/clearRecent';

const AddArtist = ({
  updateArtist: updatedArtist,
  updateArtistAction: updateArtist,
  refetch,
  artistData,
  clearRecentAction: clearRecent,
}) => {
  const [status, setStatus] = useState('');
  const [errors, setErrors] = useState([]);
  const [img, setImg] = useState(null);
  const [currentImg, setCurrentImg] = useState(null);
  const [fileKey, setFileKey] = useState('');
  const [fileSaved, setFileSaved] = useState(false);
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  useEffect(() => {
    setCurrentImg(typeof artistData !== 'undefined' ? artistData : null);
    if (updatedArtist.status === 'success') {
      $('#updateArtistModal').modal('hide');
      clearRecent();
      setStatus('');
      setErrors([]);
      setImg(null);
      if (triggerRefetch) refetch();
      setTriggerRefetch(false);
    }
    if (updatedArtist.status === 'error') {
      setStatus('');
      return setErrors([updatedArtist.error.message]);
    }

    return undefined;
  }, [updatedArtist]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let payloadPic;
    if (fileKey !== artistData) {
      payloadPic = new FormData();
      payloadPic.append('profilePhotoLink', fileKey);
    }

    if (payloadPic) {
      updateArtist(payloadPic);
      setTriggerRefetch(true);
    } else {
      return setErrors(['Please change any thing, to update!']);
    }
    setErrors([]);
    return setStatus('submitting');
  };

  const updateErrors = (error) => {
    setErrors(error);
  };

  const updateFileKey = (error) => {
    setFileKey(error);
  };

  const updateFileSaved = (saved) => {
    setFileSaved(saved);
  };

  return (
    <div
      className="modal fade"
      id="updateArtistModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="updateArtistModal"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content bg-secondary text-white border-0 artist-modal">
          <div className="modal-header">
            <h6 className="modal-title mx-auto text-center" id="formModal">
              Update Profile Photo
            </h6>
            <span
              data-dismiss="modal"
              className="close-button cursor-pointer text-center"
            >
              &times;
            </span>
          </div>
          <div className="modal-body">
            <div className="modal-form-div">
              <form action="" className="">
                {errors.map((error, index) => (
                  <div
                    className="alert alert-warning text-center"
                    role="alert"
                    key={index}
                  >
                    {error}
                    <button
                      type="button"
                      className="close"
                      onClick={() => setErrors([])}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ))}
                <Upload
                  defaultValue2={currentImg}
                  changeValue
                  updateErrors={updateErrors}
                  updateFileSaved={updateFileSaved}
                  updateFileKey={updateFileKey}
                  uploadType="updateArtist"
                  id="updateArtistFile"
                  accept=".png,.jpg"
                  status={status}
                />
                <div className="form-group mt-4 text-center">
                  {status === 'submitting' ? (
                    <button
                      type="submit"
                      className="btn btn-secondary py-1 px-4"
                      disabled
                    >
                      Updating
                      <span
                        className="spinner-border spinner-border ml-2"
                        style={{
                          width: '1.2rem',
                          height: '1.2rem',
                          fontSize: '0.5rem',
                        }}
                        role="status"
                        aria-hidden="true"
                      />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary py-1 px-4"
                      onClick={handleSubmit}
                      disabled={!!((!fileSaved && img) || !fileKey)}
                    >
                      Update Picture
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AddArtist.propTypes = {
  updateArtist: PropTypes.objectOf(PropTypes.any).isRequired,
  updateArtistAction: PropTypes.func.isRequired,
  clearRecentAction: PropTypes.func.isRequired,
  artistData: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

const mapStateProps = ({ updateArtist }) => ({ updateArtist });

export default connect(mapStateProps, {
  updateArtistAction,
  clearRecentAction,
})(AddArtist);
