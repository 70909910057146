import axios, { config } from '..';
import { ADD_SET_SUCCESS, ADD_SET_FAILED } from '../../actionTypes';

export default (body) => async (dispatch) => {
  try {
    const response = await axios.post('/music-sets/new', body, config);

    const {
      data: { message, data },
    } = response;
    dispatch({
      type: ADD_SET_SUCCESS,
      message,
      results: data,
    });
  } catch (err) {
    let error = {};
    if (err.response) {
      const {
        data: { status, message },
      } = err.response;
      error = { status, message };
    } else {
      error = {
        status: 500,
        message: err.message,
      };
    }
    dispatch({ type: ADD_SET_FAILED, error });
  }
};
