import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Provider } from 'react-redux';
import store from '../redux/store';
import SignUpSuccess from './SignUpSuccess';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import ResetPwdSuccess from './ResetPwdSuccess';
import Home from './Home';
import SignUp from './SignUp';
import Artist from './Artist';
import Set from './Set';
import Payouts from './Payouts';
import Settings from './Settings';
import NewPassword from './NewPassword';
import UpdateTempProfile from './UpdateTempProfile';
import TempUpdateSuccess from './TempUpdateSuccess';
import NotFound from './NotFound';
import Terms from './Terms';
import Privacy from './Privacy';

library.add(fab, fas);

export default function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/reset-password" component={ForgotPassword} />
          <Route
            exact
            path="/reset-password/success"
            component={ResetPwdSuccess}
          />
          <Route exact path="/signup/success" component={SignUpSuccess} />
          <Route
            exact
            path="/application/update"
            component={UpdateTempProfile}
          />
          <Route
            exact
            path="/application/update/success"
            component={TempUpdateSuccess}
          />
          <Route exact path="/artist" component={Artist} />
          <Route exact path="/sets/:id" component={Set} />
          <Route exact path="/payouts" component={Payouts} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/new-password" component={NewPassword} />
          <Route exact path="/terms-and-conditions" component={Terms} />
          <Route exact path="/privacy-policy" component={Privacy} />

          {/* NOT FOUND */}
          <Route exact path="/*" component={NotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}
