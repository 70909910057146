import {
  ADD_SET_SUCCESS,
  ADD_SET_FAILED,
  CLEAR_ADD_SET,
} from '../../actionTypes';

const initialState = {
  status: '',
  message: '',
  results: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_SET_SUCCESS:
      return {
        ...state,
        status: 'success',
        message: action.message,
        results: action.results,
      };
    case ADD_SET_FAILED:
      return { ...state, status: 'error', error: action.error };
    case CLEAR_ADD_SET:
      return { ...initialState, status: 'clearAdd_set' };
    default:
      return state;
  }
};
