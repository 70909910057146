import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import validator from '../helpers/validator';
import resetPasswordAction from '../redux/actions/user/resetPassword';
import withBackground from '../withBackground';
import musicPic from '../assets/images/musicSvg.png';

const ForgotPassword = ({ resetPasswordAction: reset, resetPassword }) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('INGOMA_ARTIST_TOKEN')) return history.push('/');
    setSubmitting(false);
    if (resetPassword.status === 'success') {
      setEmail('');
      return history.push('/reset-password/success');
    }
    if (resetPassword.status === 'error') {
      return setErrors([resetPassword.error.message]);
    }
    return undefined;
  }, [resetPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { email };
    const validationErrors = await validator(data);
    if (validationErrors.length > 0) return setErrors(validationErrors);
    setErrors([]);
    setSubmitting(true);
    return reset(data);
  };

  return (
    <>
      <div className="register-content mx-auto mt-3 py-3">
        <div className="row">
          <div className="col-md-6 div-1">
            <div className="auth-form">
              <h3 className="text-white font-weight-bold">Reset Password</h3>
              <p className="text-white" style={{ marginTop: 20 }}>
                Please submit your e-mail and we’ll send a link to reset your
                password.
              </p>
              <form className="mt-3">
                {errors.map((error, index) => (
                  <div className="alert alert-warning" role="alert" key={index}>
                    {error}
                    <button
                      type="button"
                      className="close"
                      onClick={() => setErrors([])}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ))}
                <div className="form-row">
                  <div className="form-group col-12 mt-4">
                    <div className="small-form-input-div">
                      <input
                        type="text"
                        className="form-control yellow-input"
                        placeholder="E-mail"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-primary py-1 mt-4 px-5"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!!(submitting || !email)}
                  >
                    {submitting ? (
                      <span>
                        Submit
                        <span className="spinner-border spinner-border-sm text-dark ml-2" />
                      </span>
                    ) : (
                      <span>Submit</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6 div-2">
            <div className="form-img-div">
              <img
                src={musicPic}
                alt="right-form-pic"
                className="right-form-image"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ForgotPassword.propTypes = {
  resetPassword: PropTypes.objectOf(PropTypes.any).isRequired,
  resetPasswordAction: PropTypes.func.isRequired,
};

const mapStateToProps = ({ resetPassword }) => ({
  resetPassword,
});

export default connect(mapStateToProps, {
  resetPasswordAction,
})(withBackground(ForgotPassword, 'forgot-password'));
