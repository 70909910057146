/* eslint-disable operator-linebreak */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useProfile from './useProfile';
import Sidebar from './components/Sidebar';
import BreadCrump from './components/BreadCrump';
import Error from './components/Error';
import logo from './assets/images/logo.png';
import logoSmall from './assets/images/logoSmall.png';

const withSidebar = (Component, active) => (props) => {
  const history = useHistory();
  const [status, profile] = useProfile();

  useEffect(() => {
    if (!localStorage.getItem('INGOMA_ARTIST_TOKEN')) {
      return history.push('/login');
    }
    return undefined;
  }, []);

  const sidebarData =
    status === 'success' && profile.length
      ? profile
      : JSON.parse(localStorage.getItem('INGOMA_SIDEBAR_DATA'));

  if (status === 'fetching' || !status) {
    return (
      <div className="row mx-0">
        <div className="col-sm-2 px-0">
          <Sidebar active={active} profile={sidebarData} />
        </div>
        <div className="container col-sm-10 px-4 pt-3 pb-5">
          <BreadCrump path={active} />
          <div className="loading-container pl-5 pt-5">
            <div className="pl-5 pt-5">
              <div className="ring ml-5 mt-5">
                <img
                  src={logoSmall}
                  width="50%"
                  className="load-img"
                  alt="logo"
                />
                <span className="loader-line"> </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (status === 'success' && profile.firstname) {
    return (
      <div className="row mx-0">
        <div className="col-sm-2 px-0">
          <Sidebar active={active} profile={profile} />
        </div>
        <div className="container col-sm-10 px-4 pt-3 pb-5">
          <BreadCrump path={active} />
          <Component {...props} profile={profile} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="row mx-0">
        <div className="col-sm-2 px-0">
          <Sidebar active={active} profile={null} />
        </div>
        <div className="container col-sm-10 px-4 pt-3 pb-5">
          <BreadCrump path={active} />
          <div className="loading-container">
            <div className="text-center">
              <img src={logo} width="35%" className="load-img" alt="logo" />
              <Error
                title="Oooops! Something went wrong."
                description="Please try again in a while."
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withSidebar;
