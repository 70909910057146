import * as yup from 'yup';

const SUPPORTED_IMG_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

const validators = {
  email: yup
    .string()
    .email('Please use a valid email')
    .required('Please enter an email'),
  password: yup
    .string()
    .min(6, 'Password must have atleast 6 characters!')
    .max(15, 'Password must not exceed 15 characters!'),
  oldPassword: yup
    .string()
    .min(6, 'Password must have atleast 6 characters!')
    .max(15, 'Password must not exceed 15 characters!'),
  newPassword: yup
    .string()
    .min(6, 'Password must have atleast 6 characters!')
    .max(15, 'Password must not exceed 15 characters!'),
  phoneNumber: yup
    .string()
    .matches(
      /^[7][2|3|8][0-9]{7}$/,
      'Tel must be 9 digits and start with "7..."'
    ),
  accountNumber: yup
    .string()
    .matches(/^[7][8][0-9]{7}$/, 'Tel must be 9 digits and start with "78..."'),
  img: yup
    .mixed()
    .required('Image is required')
    .test(
      'fileFormat',
      'Only jpeg/jpg/png images are allowed',
      (value) => value && SUPPORTED_IMG_FORMATS.includes(value.type)
    ),
  currentFile: yup
    .mixed()
    .required('Image is required')
    .test(
      'fileFormat',
      'Only jpeg/jpg/png images are allowed',
      (value) => value && SUPPORTED_IMG_FORMATS.includes(value.type)
    ),
  urlToken: yup
    .string()
    .matches(
      /^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_.+/=]*$/,
      'The token passed is invalid'
    ),
};

export default (value) => {
  const selected = {};
  Object.keys(value).forEach((key) => {
    selected[key] = validators[key];
  });
  return yup
    .object()
    .shape(selected)
    .validate(value, { abortEarly: false })
    .catch((err) => err.errors);
};
