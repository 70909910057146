import { CLEAR_SIGNUP, CLEAR_FORGOT_PASSWORD } from '../../actionTypes';

export default (type) => async (dispatch) => {
  if (type === 'signup') {
    dispatch({
      type: CLEAR_SIGNUP,
    });
  } else {
    dispatch({
      type: CLEAR_FORGOT_PASSWORD,
    });
  }
};
